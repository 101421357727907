import { dialogue } from '../../dialogue.js';
import { combat } from '../../combat.js';
import { player } from '../../player.js';
import { util } from '../../util.js';
import { location } from '../index.js';

const returnOption = [{ display: 'Return to map', function: () => combat.returnToMap() }];

const forestTreasure = (locationName, x, y) => ({
  text: 'You found 100 gold.',
  function: () => {
    util.updateGold(100);
    location.addModifiedTile(locationName, {
      type: 'path',
      x,
      y,
      tileImage: null,
      dialogueFunction: null,
      displayFunction: null,
      noMovement: null,
    });
    location.displayLocation(locationName);
  },
});

const goblinForestDialogues = {
  goblinForest: {
    combat: {
      text: 'What will you do?',
      manualOptions: true,
      function: () => {
        dialogue.createOptionButtons(combat.getAttackOptions());
      },
    },
    items: {
      text: 'What will you use?',
      manualOptions: true,
      function: () => {
        dialogue.createOptionButtons([
          ...player.inventory
            .filter(item => item.type === 'combatItem')
            .map(item => ({
              display: `Use ${item.name.toLowerCase()}${item.uses ? ` (${item.uses} use${item.uses > 1 ? 's' : ''} remaining)` : ''}`,
              next: item.useDialogue,
            })),
          {
            display: 'Nevermind',
            next: 'goblinForest.combat',
          },
        ]);
      },
    },
    firstGoblet: {
      text: 'A goblet appears! Goblets are miniture goblins that they keep as pets.',
      next: 'goblinForest.combat',
    },
    firstDefeatGoblet: {
      text: 'You defeated the goblet. They say, "There are no bad goblets, only bad owners."',
      options: returnOption,
    },
    goblet: {
      textFunction: () => {
        const textOptions = [
          'A goblet appears! It looks like it is trying to be sneaky.',
          'A goblet jumps out from behind a tree! It looks pretty cute, but it is still dangerous.',
          'A goblet is blocking your path! Well, I guess you could walk around it, but it might bite you.',
        ];
        return textOptions[Math.floor(Math.random() * textOptions.length)];
      },
      next: 'goblinForest.combat',
    },
    defeatGoblet: {
      textFunction: () => {
        const textOptions = [
          'The goblet falls to the ground. He was probably a good boy.',
          'You defeated the goblet. Maybe they wanted to be a goblin when they grew up?',
          'You defeated the goblet. It was probably just following orders.',
        ];
        return textOptions[Math.floor(Math.random() * textOptions.length)];
      },
      options: returnOption,
    },
    firstGoblin: {
      text: 'A goblin appears! These ruthless minions of the Goblin King are responsible for thefts and attacks in the forest.',
      next: 'goblinForest.combat',
    },
    firstDefeatGoblin: {
      text: 'You defeated the goblin. Is it okay to take its gold if it is stolen? You decide to take it anyway.',
      options: returnOption,
    },
    goblin: {
      textFunction: () => {
        const textOptions = [
          'A goblin appears! I wonder how long they have been watching you...',
          'A goblin jumps out from behind a tree! They seem to be getting more aggressive.',
          'A goblin is blocking your path! How many of them are there?',
        ];
        return textOptions[Math.floor(Math.random() * textOptions.length)];
      },
      next: 'goblinForest.combat',
    },
    defeatGoblin: {
      textFunction: () => {
        const textOptions = [
          'The goblin falls to the ground. It looks like it was just a kid. Maybe they are forced to do this?',
          'You defeated the goblin. It had some gold in its backpack.',
          'You defeated the goblin. Hopefully it learned its lesson.',
        ];
        return textOptions[Math.floor(Math.random() * textOptions.length)];
      },
      options: returnOption,
    },
    firstOgre: {
      text: "An ogre appears! A meaty creature that isn't too bright, but it is very strong. Hopefully you can defeat it...",
      next: 'goblinForest.combat',
    },
    firstDefeatOgre: {
      text: "You defeated the ogre. If he wasn't working for the Goblin King, he probably would have been friendly.",
      options: returnOption,
    },
    ogre: {
      textFunction: () => {
        const textOptions = [
          'An ogre appears! He gives you an angry look and clenches his fist around his club.',
          'An ogre jumps out from behind a tree! He looks like he is ready to fight.',
          "An ogre is blocking your path! Maybe you could sneak between his legs? No, that wouldn't work.",
        ];
        return textOptions[Math.floor(Math.random() * textOptions.length)];
      },
      next: 'goblinForest.combat',
    },
    defeatOgre: {
      textFunction: () => {
        const textOptions = [
          "The ogre falls to the ground. At least he won't be causing any more trouble.",
          'You defeated the ogre. I hope he was the last one in the forest.',
          'You defeated the ogre. He probably would have been a good friend if he was not so angry.',
        ];
        return textOptions[Math.floor(Math.random() * textOptions.length)];
      },
      options: returnOption,
    },
    ogreSpray: {
      textFunction: () => {
        if (player.currentEnemy?.name !== 'ogre') return 'You cannot use the ogre spray on this enemy. What will you do?';
        const ogreSpray = player.inventory.find(item => item.name === 'Ogre Spray');
        ogreSpray.uses -= 1;
        if (ogreSpray.uses === 0) {
          // Remove the ogre spray that was used up.
          player.inventory = player.inventory.filter(item => item.uses !== 0);
        }
        return 'You used the ogre spray. The ogre ran away! Hopefully he will not come back...';
      },
      manualOptions: true,
      function: () => {
        if (player.currentEnemy.name === 'ogre') dialogue.createOptionButtons(returnOption);
        else dialogue.createOptionButtons(combat.getAttackOptions());
      },
    },
    smallHealthPotion: {
      textFunction: () => {
        const smallHealthPotion = player.inventory.find(item => item.name === 'Small Health Potion');
        if (!smallHealthPotion) return 'You do not have a small health potion. What will you do?';
        smallHealthPotion.uses -= 1;
        player.inventory = player.inventory.filter(item => item.uses !== 0);
        const oldHp = player.hp;
        player.hp = Math.min(player.hp + 50, player.maxHp);
        const newHp = player.hp;
        util.updateHealthBar();
        return `You used the small health potion. You gained ${newHp - oldHp} health points!`;
      },
      manualOptions: true,
      function: () => {
        dialogue.createOptionButtons(combat.getAttackOptions());
      },
    },
    smallMagicPotion: {
      textFunction: () => {
        const smallMagicPotion = player.inventory.find(item => item.name === 'Small Magic Potion');
        if (!smallMagicPotion) return 'You do not have a small magic potion. What will you do?';
        smallMagicPotion.uses -= 1;
        player.inventory = player.inventory.filter(item => item.uses !== 0);
        const oldMp = player.mp;
        player.mp = Math.min(player.mp + 9, player.maxMp);
        const newMp = player.mp;
        util.updateMagicBar();
        return `You used the small magic potion. You gained ${newMp - oldMp} magic points!`;
      },
      manualOptions: true,
      function: () => {
        dialogue.createOptionButtons(combat.getAttackOptions());
      },
    },
    treasureRight1: forestTreasure('goblinForestRight2', 7, 2),
    treasureRight2: forestTreasure('goblinForestRight2', 7, 5),
  },
};

export { goblinForestDialogues };
