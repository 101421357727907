import { player } from './player.js';
import { dialogue } from './dialogue.js';
import { location } from './location/index.js';

let experienceTable = {};
for (let i = 1; i <= 100; i++) {
  let thisLevel = i;
  // n^3 - 1 is the experience formula in Glenfall. n^3 is the formula used for medium fast leveling in Pokemon.
  experienceTable[thisLevel] = thisLevel * thisLevel * thisLevel - 1;
}

const updateHealthBar = async (healthBarId, hp, maxHp) => {
  const hpDisplayId = healthBarId === 'enemyHealthLevel' ? 'enemyHpDisplay' : 'hpDisplay';
  if (typeof hp !== 'number') hp = player.hp;
  if (typeof maxHp !== 'number') maxHp = player.maxHp;
  document.getElementById(hpDisplayId).innerHTML = `${hp}/${maxHp}`;

  const healthBar = document.getElementById(healthBarId || 'healthLevel');
  let percentage = (hp / maxHp) * 100;
  if (percentage < 0) percentage = 0;
  healthBar.style.width = percentage + '%';

  if (percentage > 60) healthBar.style.backgroundColor = '#228b22';
  else if (percentage > 30) healthBar.style.backgroundColor = '#CCAA00';
  else healthBar.style.backgroundColor = '#AB0000';
};

const updateExperienceBar = async () => {
  const experienceBar = document.getElementById('experienceLevel');
  const xpToNextLevel = experienceTable[player.level + 1] - experienceTable[player.level];
  let percentage = ((player.experience - experienceTable[player.level]) / xpToNextLevel) * 100;
  if (percentage < 0) percentage = 0;
  if (percentage > 100) percentage = 100;
  experienceBar.style.width = percentage + '%';
  // Wait for the experience bar to finish filling before continuing.
  await delay(1000);
  if (percentage == 100) await levelUp();
};

const levelUp = async () => {
  const experienceBar = document.getElementById('experienceLevel');
  experienceBar.style.width = 0;
  await dialogue.writeText(`Congratulations! You have reached level ${player.level + 1}!`);
  // A little extra delay so the experience bar can finish resetting.
  await delay(100);
  player.level++;
  player.skillPoints++;

  // The stat increases are arbitrary and can be adjusted as needed.
  player.maxHp += 4;
  player.hp = player.maxHp;
  if (player.level % 2 === 1) player.maxMp += 1;
  player.mp = player.maxMp;
  updateMagicBar();
  player.attack += 2;
  player.magicAttack += 2;
  player.defense += 2;
  player.magicDefense += 2;
  updateHealthBar();
  await updateExperienceBar();
};

const updateMagicBar = () => {
  document.getElementById('mpDisplay').innerHTML = `${player.mp}/${player.maxMp}`;

  const magicBar = document.getElementById('magicLevel');
  let percentage = (player.mp / player.maxMp) * 100;
  if (percentage < 0) percentage = 0;
  magicBar.style.width = percentage + '%';
};

const updateGold = amount => {
  if (amount) player.gold += amount;
};

const loadSave = async slot => {
  slot = slot || 0;
  const saves = JSON.parse(localStorage.getItem('save'));
  player.saveId = slot;
  if (saves?.length && saves[slot]) {
    for (const key in player) {
      if (Object.prototype.hasOwnProperty.call(saves[slot], key)) player[key] = saves[slot][key];
    }
  }

  if (!player.hideHealth) revealHealthAndXp();
  if (!player.hideMagic) revealMagic();

  if (!dialogue.checkDialogue('elarianGates.intro')) dialogue.displayDialogue({ dialogueName: 'elarianGates.intro' });
  else dialogue.clearScreen();
  // Display location second because the location might contain dialogue.
  location.displayLocation(player.currentLocation);

  if (player.hp > 0) location.showDisplay();
};

// The goal is to save the game every time the player object is updated.
const saveGame = () => {
  const save = JSON.parse(localStorage.getItem('save'));
  let saveFiles = save?.length ? save : [];
  if (saveFiles.length) saveFiles = saveFiles.map((save, index) => (index === player.saveId ? { ...player } : save));
  else saveFiles = [{ ...player }];
  localStorage.setItem('save', JSON.stringify(saveFiles));
};

// Health and magic are revealing during the tutorial.
const revealHealthAndXp = () => {
  player.hideHealth = false;
  document.getElementById('hpDisplay').innerHTML = `${player.hp}/${player.maxHp}`;
  document.getElementById('healthAndXpContainer').style.display = 'flex';
  updateHealthBar();
  updateExperienceBar();
};

const revealMagic = () => {
  player.hideMagic = false;
  document.getElementById('mpDisplay').innerHTML = `${player.mp}/${player.maxMp}`;
  document.getElementById('magicContainer').style.display = 'flex';
  updateMagicBar();
};

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const util = {
  updateHealthBar,
  updateMagicBar,
  updateExperienceBar,
  updateGold,
  loadSave,
  saveGame,
  revealHealthAndXp,
  revealMagic,
  delay,
};
