import { elarianLocations } from './elarian/locations.js';
import { goblinForestLocations } from './goblinForest/locations.js';
import { mirewoodLocations } from './mirewood/locations.js';
import { util } from '../util.js';
import { player } from '../player.js';
import { dialogue } from '../dialogue.js';
import { npc } from '../npc/index.js';
import { combat } from '../combat.js';

const locations = [...elarianLocations, ...goblinForestLocations, ...mirewoodLocations];
let isMoving = false;

// Use URL for images so that parcel knows to include them in the build.
const images = {
  path: {
    cobblestone: new URL('../../images/cobblestone.png?width=256&as=webp', import.meta.url),
    grass: new URL('../../images/grass.png?width=256&as=webp', import.meta.url),
    woodFloor: new URL('../../images/wood-floor.png?width=256&as=webp', import.meta.url),
    forestGrass: new URL('../../images/forest-grass.png?width=256&as=webp', import.meta.url),
    swampFloor: new URL('../../images/swamp-floor.jpg?width=256&as=webp', import.meta.url),
  },
  wall: {
    stoneBrick: new URL('../../images/stone-brick.png?width=256&as=webp', import.meta.url),
  },
  enemy: {
    rat: new URL('../../images/rat.png?width=512&as=webp', import.meta.url),
    ratBoss: new URL('../../images/rat-boss.png?width=512&as=webp', import.meta.url),
    goblin: new URL('../../images/goblin.png?width=512&as=webp', import.meta.url),
    goblet: new URL('../../images/goblet.png?width=512&as=webp', import.meta.url),
    ogre: new URL('../../images/ogre.png?width=512&as=webp', import.meta.url),
    goblinKing: new URL('../../images/goblin-king.png?width=512&as=webp', import.meta.url),
  },
  thaddeus: new URL('../../images/thaddeus.png?width=512&as=webp', import.meta.url),
  entrance: {
    door: new URL('../../images/door.png?width=256&as=webp', import.meta.url),
    cellarDoor: new URL('../../images/cellar-door.png?width=256&as=webp', import.meta.url),
    castleGate: new URL('../../images/castle-gate.png?width=256&as=webp', import.meta.url),
    cathedral: new URL('../../images/cathedral.png?width=256&as=webp', import.meta.url),
    tower: new URL('../../images/tower.png?width=256&as=webp', import.meta.url),
    shop: new URL('../../images/shop.png?width=256&as=webp', import.meta.url),
    castle: new URL('../../images/castle.png?width=256&as=webp', import.meta.url),
    goblinCamp: new URL('../../images/goblin-camp.png?width=256&as=webp', import.meta.url),
    witchHut: new URL('../../images/witch-hut.png?width=256&as=webp', import.meta.url),
    mirewoodTavern: new URL('../../images/mirewood-tavern.png?width=256&as=webp', import.meta.url),
    mirewoodHouse: new URL('../../images/mirewood-house.png?width=256&as=webp', import.meta.url),
  },
  npc: {
    guard: new URL('../../images/guard.png?width=512&as=webp', import.meta.url),
    wizardEldwin: new URL('../../images/wizard-eldwin.png?width=512&as=webp', import.meta.url),
    shopkeeperCedric: new URL('../../images/shopkeeper-cedric.png?width=512&as=webp', import.meta.url),
    fatherMichael: new URL('../../images/father-michael.png?width=512&as=webp', import.meta.url),
    king: new URL('../../images/king.png?width=512&as=webp', import.meta.url),
    jesterJeffrey: new URL('../../images/jester-jeffrey.png?width=512&as=webp', import.meta.url),
    ratBoss: new URL('../../images/rat-boss.png?width=512&as=webp', import.meta.url),
    goblinKing: new URL('../../images/goblin-king.png?width=512&as=webp', import.meta.url),
    goblin: new URL('../../images/goblin.png?width=512&as=webp', import.meta.url),
    witch: new URL('../../images/witch.png?width=512&as=webp', import.meta.url),
  },
  object: {
    bookshelf: new URL('../../images/bookshelf.png?width=256&as=webp', import.meta.url),
    altar: new URL('../../images/altar.png?width=256&as=webp', import.meta.url),
    cauldron: new URL('../../images/cauldron.png?width=256&as=webp', import.meta.url),
    counter: new URL('../../images/counter.png?width=256&as=webp', import.meta.url),
    stainedGlass1: new URL('../../images/stained-glass-1.png?width=256&as=webp', import.meta.url),
    stainedGlass2: new URL('../../images/stained-glass-2.png?width=256&as=webp', import.meta.url),
    stainedGlass3: new URL('../../images/stained-glass-3.png?width=256&as=webp', import.meta.url),
    stainedGlass4: new URL('../../images/stained-glass-4.png?width=256&as=webp', import.meta.url),
    throne: new URL('../../images/throne.png?width=256&as=webp', import.meta.url),
    basiliskStatue: new URL('../../images/basilisk-statue.png?width=256&as=webp', import.meta.url),
    dragonStatue: new URL('../../images/dragon-statue.png?width=512&as=webp', import.meta.url),
    tree: new URL('../../images/tree.png?width=256&as=webp', import.meta.url),
    chest: new URL('../../images/chest.png?width=256&as=webp', import.meta.url),
    giantTree: new URL('../../images/giant-tree.png?width=512&as=webp', import.meta.url),
  },
};

const customTileStyles = {
  object: {
    counter: {
      width: '150%',
      height: '150%',
      left: '-10px',
      bottom: '20px',
      'z-index': 2,
    },
    bookshelf: {
      width: '120%',
      height: '120%',
      left: '-7px',
      bottom: '-4px',
    },
    cauldron: {
      width: '125%',
      height: '125%',
      left: '-7px',
      bottom: '25px',
      'z-index': 2,
    },
    altar: {
      width: '200%',
      height: '200%',
      left: '-32px',
      bottom: '-25px',
    },
    throne: {
      width: '160%',
      height: '160%',
      left: '-20px',
      bottom: '45px',
    },
    dragonStatue: {
      width: '250%',
      height: '250%',
      left: '-42px',
      bottom: '-17px',
      'z-index': 2,
    },
    basiliskStatue: {
      width: '200%',
      height: '200%',
      left: '-30px',
      bottom: '-5px',
      'z-index': 2,
    },
    tree: {
      width: '200%',
      height: '200%',
      left: '-30px',
      bottom: '-5px',
      'z-index': 2,
    },
    giantTree: {
      width: '500%',
      height: '500%',
      left: '-119px',
      bottom: '50px',
      'z-index': 2,
    },
  },
  entrance: {
    cathedral: {
      width: '200%',
      height: '200%',
      left: '-30px',
      bottom: '0px',
      'z-index': 2,
    },
    tower: {
      width: '200%',
      height: '200%',
      left: '-30px',
      bottom: '-5px',
      'z-index': 2,
    },
    shop: {
      width: '200%',
      height: '200%',
      left: '-30px',
      bottom: '-12px',
      'z-index': 2,
    },
    castle: {
      width: '200%',
      height: '200%',
      left: '-30px',
      bottom: '-7px',
      'z-index': 2,
    },
    goblinCamp: {
      width: '200%',
      height: '200%',
      left: '-30px',
      bottom: '-5px',
      'z-index': 2,
    },
    witchHut: {
      width: '200%',
      height: '200%',
      left: '-30px',
      bottom: '-10px',
      'z-index': 3,
    },
    mirewoodTavern: {
      width: '200%',
      height: '200%',
      left: '-30px',
      bottom: '-5px',
      'z-index': 2,
    },
    mirewoodHouse: {
      width: '200%',
      height: '200%',
      left: '-30px',
      bottom: '-5px',
      'z-index': 2,
    },
  },
};

// The max height if there is no dialogue is 9 tiles, otherwise it is 7 tiles.
// The max width is 9 tiles.
const createLayout = locationName => {
  const location = locations.find(location => location.name === locationName);
  const wallObject = location.wallObject || { type: 'wall' };
  const walls = location.walls || [];

  let layout = [];
  for (let y = 0; y < location.height; y++) {
    for (let x = 0; x < location.width; x++) {
      if (walls.includes('top') && y === location.height - 1) layout.push({ ...wallObject, x, y });
      else if (walls.includes('bottom') && y === 0) layout.push({ ...wallObject, x, y });
      else if (walls.includes('left') && x === 0) layout.push({ ...wallObject, x, y });
      else if (walls.includes('right') && x === location.width - 1) layout.push({ ...wallObject, x, y });
      // Since most locations are square, default to a path tile. The 'empty' type can be used to create gaps in the layout.
      else layout.push({ type: 'path', x, y });
    }
  }

  layout = layout.map(pathTile => {
    const tile = location.tiles.find(tile => tile.x === pathTile.x && tile.y === pathTile.y);
    if (tile) return tile;
    return pathTile;
  });

  return layout;
};

const getLocationTiles = locationName => {
  const locationTiles = createLayout(locationName);

  // Combine the location's tiles with the player's modified tiles.
  if (!player.modifiedTiles[locationName]) player.modifiedTiles[locationName] = [];
  const modifiedTiles = player.modifiedTiles[locationName];
  const tiles = locationTiles.map(tile => ({
    ...tile,
    ...(modifiedTiles.find(modifiedTile => modifiedTile.x === tile.x && modifiedTile.y === tile.y) || {}),
  }));

  // Combine the location's NPCs with the player's modified NPCs.
  const npcArray = npc.npcList
    .map(npc => ({
      ...npc,
      ...(player.modifiedNpcs.find(modifiedNpc => modifiedNpc.name === npc.name) || {}),
    }))
    .filter(npc => npc.location === locationName);

  // Add the NPCs to the tiles.
  return tiles.map(tile => ({
    ...tile,
    ...(npcArray.find(npc => npc.x === tile.x && npc.y === tile.y) || {}),
  }));
};

const addModifiedTile = (locationName, tile) => {
  // If the tile is already in the modified tiles array, then add the new values to that object.
  const modifiedTile = player.modifiedTiles[locationName].find(modifiedTile => modifiedTile.x === tile.x && modifiedTile.y === tile.y);
  if (modifiedTile) {
    player.modifiedTiles[locationName] = player.modifiedTiles[locationName].map(modifiedTile =>
      modifiedTile.x === tile.x && modifiedTile.y === tile.y ? { ...modifiedTile, ...tile } : modifiedTile,
    );
  } else {
    player.modifiedTiles[locationName].push(tile);
  }
};

const addModifiedNpc = npc => {
  // If the NPC is already in the modified NPCs array, then add the new values to that object.
  const modifiedNpc = player.modifiedNpcs.find(modifiedNpc => modifiedNpc.name === npc.name);
  if (modifiedNpc) {
    player.modifiedNpcs = player.modifiedNpcs.map(modifiedNpc => (modifiedNpc.name === npc.name ? { ...modifiedNpc, ...npc } : modifiedNpc));
  } else {
    player.modifiedNpcs.push(npc);
  }
};

const displayLocation = async (locationName, entranceName) => {
  player.currentLocation = locationName;
  const tiles = getLocationTiles(locationName);

  if (entranceName) {
    const entrance = tiles.find(tile => tile.type === 'entrance' && tile.location === entranceName);
    player.x = entrance.x;
    player.y = entrance.y;

    // Every entrance has a direction that it outputs the player to.
    const entranceDirection = entrance.outputDirection;
    if (entranceDirection === 'up') player.y += 1;
    if (entranceDirection === 'down') player.y -= 1;
    if (entranceDirection === 'left') player.x -= 1;
    if (entranceDirection === 'right') player.x += 1;
  }

  const mainDisplay = document.getElementById('mainDisplay');
  mainDisplay.innerHTML = '';
  const displayDiv = document.createElement('div');
  displayDiv.classList.add('display-div');

  // Check if an enemy should display instead of the map.
  if (player.currentEnemy) {
    // Add a gap between the enemy's health bar and the image of the enemy.
    displayDiv.style.gap = '10px';
    // Display the enemy's health bar
    const healthContainer = document.createElement('div');
    healthContainer.classList.add('health-container');
    healthContainer.id = 'enemyHealthContainer';
    healthContainer.style.display = 'flex';
    const healthLabel = document.createElement('span');
    healthLabel.id = 'enemyHealthLabel';
    healthLabel.classList.add('health-label');
    healthLabel.style.textTransform = 'capitalize';
    healthLabel.textContent = player.currentEnemy.name;
    const healthBar = document.createElement('div');
    healthBar.classList.add('health-bar');
    healthBar.id = 'healthBar';
    const healthLevel = document.createElement('div');
    healthLevel.classList.add('health-level');
    healthLevel.id = 'enemyHealthLevel';
    const enemyHpDisplay = document.createElement('div');
    enemyHpDisplay.id = 'enemyHpDisplay';
    enemyHpDisplay.classList.add('hp-display');
    healthLevel.appendChild(enemyHpDisplay);
    healthBar.appendChild(healthLevel);
    healthContainer.appendChild(healthLabel);
    healthContainer.appendChild(healthBar);
    displayDiv.appendChild(healthContainer);

    const imgElement = document.createElement('img');
    imgElement.draggable = false;
    imgElement.src = images.enemy[player.currentEnemy.tileImage];
    imgElement.style.width = '200px';
    displayDiv.appendChild(imgElement);
    mainDisplay.appendChild(displayDiv);

    dialogue.displayDialogue({ dialogueName: player.currentEnemy.encounterDialogueFunction() });
    return util.updateHealthBar('enemyHealthLevel', player.currentEnemy.hp, player.currentEnemy.maxHp);
  }

  // Determine the number of rows and columns
  const rows = Math.max(...tiles.map(tile => tile.y)) + 1;
  const columns = Math.max(...tiles.map(tile => tile.x)) + 1;

  // Create a 2D array to hold the tiles
  const grid = Array.from({ length: rows }, () => Array(columns).fill(null));

  // Place each tile in the correct position in the grid
  tiles.forEach(tile => {
    grid[tile.y][tile.x] = tile;
  });
  grid.reverse();

  // Iterate through the grid and create row and tile elements
  grid.forEach(row => {
    const rowElement = document.createElement('div');
    rowElement.style.display = 'flex';

    row.forEach(tile => {
      const tileElement = document.createElement('div');
      tileElement.style.width = '55px';
      tileElement.style.height = '55px';
      tileElement.id = `${tile.x}-${tile.y}`;
      if (tile.type !== 'empty') {
        // Display a background image for the tile.
        const background = ['wall', 'path'].includes(tile.type) ? tile.type : tile.tileBackground || 'path';
        let image = '';
        if (background === 'path') {
          const pathImage = locations.find(location => location.name === locationName).pathImage;
          image = images.path[pathImage];
        } else if (background === 'wall') {
          const wallImage = locations.find(location => location.name === locationName).wallImage;
          image = images.wall[wallImage];
        }
        // This background image is displayed behind the tile image.
        tileElement.style.backgroundImage = `url(${image})`;
        tileElement.style.backgroundSize = 'cover';

        if (tile.type === 'entrance') tileElement.classList.add('entrance-tile');

        // Create an img element inside the div to display the tile image.
        const isPlayer = player.x === tile.x && player.y === tile.y;
        if ((!['wall', 'path'].includes(tile.type) && tile.tileImage) || isPlayer) {
          const imgElement = getTileImage(tile, isPlayer);
          tileElement.appendChild(imgElement);
        }
      }
      rowElement.appendChild(tileElement);
    });

    displayDiv.appendChild(rowElement);
  });

  mainDisplay.appendChild(displayDiv);
  checkNearbyInteraction();

  // Pre-load images for attached locations.
  preloadImages();
};

const preloadImages = () => {
  // Pre-load all images for all attached locations.
  const locationName = player.currentLocation;
  const tiles = getLocationTiles(locationName);

  // Attached locations are locations that there is an entrance to from the current location.
  const attachedLocations = tiles
    .filter(tile => tile.type === 'entrance')
    .map(entrance => entrance.location)
    .filter(Boolean);

  let imagesToPreload = [];
  for (const locationName of attachedLocations) {
    const location = locations.find(location => location.name === locationName);
    const locationTiles = getLocationTiles(locationName);
    const locationImages = locationTiles.map(tile => {
      return tile.tileImage && images[tile.type]?.[tile.tileImage];
    });

    // Add path and wall images.
    locationImages.push(images.path[location.pathImage], images.wall[location.wallImage]);

    imagesToPreload.push(...locationImages);
  }

  // Filter out undefined and duplicate images.
  imagesToPreload = imagesToPreload.filter((image, index) => image && imagesToPreload.indexOf(image) === index);

  for (const image of imagesToPreload) {
    const img = new Image();
    img.src = image;
  }
};

const getTileImage = (tile, isPlayer) => {
  let type = tile.type;
  let image;
  if (!['wall', 'path'].includes(type)) {
    image = images[type]?.[tile.tileImage];
  }
  let imgElement;
  if (image) {
    imgElement = document.createElement('img');
    imgElement.src = image;
    imgElement.style.position = 'absolute';
    imgElement.style.width = '100%';
    imgElement.style.height = '100%';
    imgElement.draggable = false;
    if (type === 'npc') imgElement.style.zIndex = 1;
    if (customTileStyles?.[type]?.[tile.tileImage]) {
      const customStyle = customTileStyles[type][tile.tileImage];
      for (const [key, value] of Object.entries(customStyle)) {
        imgElement.style[key] = value;
      }
    }
  }
  let playerImageElement;
  if (isPlayer) {
    playerImageElement = document.createElement('img');
    playerImageElement.src = images.thaddeus;
    playerImageElement.style.position = 'absolute';
    playerImageElement.style.zIndex = 1;
    if (tile['z-index']) playerImageElement.style.zIndex = tile['z-index'];
    playerImageElement.style.width = '100%';
    playerImageElement.style.height = '100%';
    playerImageElement.draggable = false;
  }

  const imgDiv = document.createElement('div');
  imgDiv.style.width = '100%';
  imgDiv.style.height = '100%';
  imgDiv.style.position = 'relative';
  if (imgElement) imgDiv.appendChild(imgElement);
  if (playerImageElement) imgDiv.appendChild(playerImageElement);
  return imgDiv;
};

// Sees where the player can move based on their current location and the non-empty tiles in the dungeon layout.
const availableDirections = locationName => {
  if (!player.canExitDialogue) return [];
  if (player.currentEnemy) return [];

  const tiles = getLocationTiles(locationName);
  const noMovementTypes = ['wall', 'npc'];
  const up = tiles.find(tile => tile.x === player.x && tile.y === player.y + 1 && !noMovementTypes.includes(tile.type) && !tile.noMovement);
  const down = tiles.find(tile => tile.x === player.x && tile.y === player.y - 1 && !noMovementTypes.includes(tile.type) && !tile.noMovement);
  const left = tiles.find(tile => tile.x === player.x - 1 && tile.y === player.y && !noMovementTypes.includes(tile.type) && !tile.noMovement);
  const right = tiles.find(tile => tile.x === player.x + 1 && tile.y === player.y && !noMovementTypes.includes(tile.type) && !tile.noMovement);

  const actions = [];
  if (up) actions.push('up');
  if (down) actions.push('down');
  if (left) actions.push('left');
  if (right) actions.push('right');
  return actions;
};

const findPath = (startX, startY, targetX, targetY, tiles) => {
  const noMovementTypes = ['wall', 'npc', 'empty'];
  const openList = [];
  const closedList = [];
  const path = [];

  const targetReachable = tiles.find(tile => tile.x === targetX && tile.y === targetY && !noMovementTypes.includes(tile.type) && !tile.noMovement);

  // If the target tile is not reachable, then find the closest tile to the player that is reachable.
  // Should be the closest to the target and if the distance to target is the same, then closest to the player.
  if (!targetReachable) {
    let closestTile = { x: startX, y: startY, distance: Infinity };
    for (const tile of tiles) {
      // Don't move the player to an entrance if they did not select that entrance as their target.
      if (noMovementTypes.includes(tile.type) || tile.noMovement || tile.type === 'entrance') continue;
      const distance = Math.abs(tile.x - targetX) + Math.abs(tile.y - targetY);
      if (distance < closestTile.distance) closestTile = { x: tile.x, y: tile.y, distance };
      else if (distance === closestTile.distance) {
        const playerDistance = Math.abs(tile.x - startX) + Math.abs(tile.y - startY);
        const closestPlayerDistance = Math.abs(closestTile.x - startX) + Math.abs(closestTile.y - startY);
        if (playerDistance < closestPlayerDistance) closestTile = { x: tile.x, y: tile.y, distance };
      }
    }
    targetX = closestTile.x;
    targetY = closestTile.y;
  }

  // This is the A* path finding algorithm.
  // f-score is the sum of g-score and h-score.
  // g-score is the distance to the current point, h-score is the estimated remaining distance.
  const startNode = { x: startX, y: startY, g: 0, h: 0, f: 0, parent: null };
  openList.push(startNode);

  while (openList.length) {
    openList.sort((a, b) => a.f - b.f);
    // Process the nodes by lowest f-score first so that we can find the quickest path to the target.
    const currentNode = openList.shift();
    closedList.push(currentNode);

    // If this is the target, then we found the quickest path.
    if (currentNode.x === targetX && currentNode.y === targetY) {
      let current = currentNode;
      while (current.parent) {
        path.push({ x: current.x, y: current.y });
        current = current.parent;
      }
      return path.reverse();
    }

    const adjacentTiles = [
      { x: currentNode.x, y: currentNode.y + 1 },
      { x: currentNode.x, y: currentNode.y - 1 },
      { x: currentNode.x - 1, y: currentNode.y },
      { x: currentNode.x + 1, y: currentNode.y },
    ];

    for (const adjacent of adjacentTiles) {
      const adjacentTile = tiles.find(tile => tile.x === adjacent.x && tile.y === adjacent.y);

      // Don't add tiles to the open list that are inaccessible, already been searched, or need to be avoided.
      if (!adjacentTile || noMovementTypes.includes(adjacentTile.type) || adjacentTile.noMovement) continue;
      if (closedList.find(node => node.x === adjacent.x && node.y === adjacent.y)) continue;
      if (adjacentTile.type === 'entrance' && (adjacent.x !== targetX || adjacent.y !== targetY)) continue;

      const g = currentNode.g + 1;
      const h = Math.abs(adjacent.x - targetX) + Math.abs(adjacent.y - targetY);
      const f = g + h;

      const openNode = openList.find(node => node.x === adjacent.x && node.y === adjacent.y);
      if (openNode) {
        // If the g-score is improved using this path, then update the node in the open list.
        if (g < openNode.g) {
          openNode.g = g;
          openNode.f = f;
          openNode.parent = currentNode;
        }
      } else {
        openList.push({ x: adjacent.x, y: adjacent.y, g, h, f, parent: currentNode });
      }
    }
  }

  return path;
};

const movePlayerTap = async (locationName, tileX, tileY, overrideMoveablity) => {
  isMoving = true;
  const tiles = getLocationTiles(locationName);

  // Find a path to the target tile. The player cannot move to wall, npc, empty, or noMovement tiles.
  const path = findPath(player.x, player.y, tileX, tileY, tiles);

  if (!path.length) return (isMoving = false);
  dialogue.createOptionButtons();

  for (let i = 0; i < path.length; i++) {
    const nextTile = path[i];
    const direction = nextTile.x < player.x ? 'left' : nextTile.x > player.x ? 'right' : nextTile.y < player.y ? 'down' : 'up';
    // If they cannot move to the tile, then abort the movement.
    if (!overrideMoveablity && !availableDirections(locationName).includes(direction)) return (isMoving = false);
    const eventOccured = movePlayer(locationName, direction, true);
    if (eventOccured) break;
    if (i !== path.length - 1) await util.delay(200);
  }

  if (!overrideMoveablity) {
    dialogue.clearScreen();
    checkNearbyInteraction();
  }

  isMoving = false;
};

const movePlayer = (locationName, direction, npcPush) => {
  if (!npcPush && !availableDirections(locationName).includes(direction)) return;
  const tiles = getLocationTiles(locationName);

  let newPlayer = { x: player.x, y: player.y };
  switch (direction) {
    case 'up':
      newPlayer.y += 1;
      break;
    case 'down':
      newPlayer.y -= 1;
      break;
    case 'left':
      newPlayer.x -= 1;
      break;
    case 'right':
      newPlayer.x += 1;
      break;
  }

  // Find the tile the player is moving to
  const targetTile = tiles.find(tile => tile.x === newPlayer.x && tile.y === newPlayer.y);
  if (targetTile.type === 'entrance' && !targetTile.locked) {
    dialogue.clearScreen();
    return displayLocation(targetTile.location, locationName);
  } else if (targetTile.type === 'entrance' && targetTile.locked) return dialogue.displayDialogue({ dialogueName: targetTile.lockedDialogue });

  // Move the player to the new tile
  const targetTileElement = document.getElementById(`${newPlayer.x}-${newPlayer.y}`);
  targetTileElement.innerHTML = '';
  const targetImgElement = getTileImage(targetTile, true);
  targetTileElement.appendChild(targetImgElement);

  // Reset the previous tile
  const previousTileElement = document.getElementById(`${player.x}-${player.y}`);
  previousTileElement.innerHTML = '';
  const previousTile = tiles.find(tile => tile.x === player.x && tile.y === player.y);
  if (!['wall', 'path'].includes(previousTile.type)) {
    const previousImgElement = getTileImage(previousTile);
    previousTileElement.appendChild(previousImgElement);
  }

  player.x = newPlayer.x;
  player.y = newPlayer.y;

  if (combat.checkForCombat()) return location.displayLocation(player.currentLocation);
  else if (!npcPush) {
    dialogue.clearScreen();
    checkNearbyInteraction();
  }
};

// Check if the player is next to an NPC, if so give the option to talk to them.
const checkNearbyInteraction = () => {
  const tiles = getLocationTiles(player.currentLocation);
  const nearbyNpc = tiles.filter(
    tile =>
      tile.type === 'npc' &&
      (((tile.x === player.x + 1 || tile.x === player.x - 1) && tile.y === player.y) ||
        ((tile.y === player.y + 1 || tile.y === player.y - 1) && tile.x === player.x)),
  );
  const nearbyInteractables = tiles.filter(
    tile =>
      tile.displayFunction &&
      tile.dialogueFunction &&
      (((tile.x === player.x + 1 || tile.x === player.x - 1) && tile.y === player.y) ||
        ((tile.y === player.y + 1 || tile.y === player.y - 1) && tile.x === player.x)),
  );

  const options = [];
  if (nearbyNpc.length) {
    for (const npc of nearbyNpc) {
      const dialogueName = npc.dialogueFunction?.();
      options.push({ display: `Talk to ${npc.name}`, function: () => dialogue.displayDialogue({ dialogueName }) });
    }
  }

  if (nearbyInteractables.length) {
    for (const interactable of nearbyInteractables) {
      const dialogueName = interactable.dialogueFunction?.();
      options.push({ display: interactable.displayFunction(), function: () => dialogue.displayDialogue({ dialogueName }) });
    }
  }

  dialogue.createOptionButtons(options);
};

const moveNpc = (locationName, npcName, direction) => {
  const tiles = getLocationTiles(locationName);
  const npc = tiles.find(tile => tile.type === 'npc' && tile.name === npcName);

  let newNpc = { x: npc.x, y: npc.y };
  switch (direction) {
    case 'up':
      newNpc.y += 1;
      break;
    case 'down':
      newNpc.y -= 1;
      break;
    case 'left':
      newNpc.x -= 1;
      break;
    case 'right':
      newNpc.x += 1;
      break;
  }

  // Move the npc to the new tile
  const targetTileElement = document.getElementById(`${newNpc.x}-${newNpc.y}`);
  targetTileElement.innerHTML = '';
  const targetImgElement = getTileImage(npc);
  targetTileElement.appendChild(targetImgElement);

  const movingToPlayerTile = newNpc.x === player.x && newNpc.y === player.y;

  // Set the previous tile to the tile type that the NPC is moving to
  const newTile = tiles.find(tile => tile.x === newNpc.x && tile.y === newNpc.y);
  if (!movingToPlayerTile) {
    const previousTileElement = document.getElementById(`${npc.x}-${npc.y}`);
    previousTileElement.innerHTML = '';
    if (!['wall', 'path'].includes(newTile.type)) {
      const previousImgElement = getTileImage(newTile);
      previousTileElement.appendChild(previousImgElement);
    }
  }

  // Update the tiles array with the new location of the NPC
  addModifiedNpc({ name: npcName, x: newNpc.x, y: newNpc.y });

  // If the NPC is moving to the player's location, then move the player to the NPC's location
  if (movingToPlayerTile) {
    const inverseDirection = { up: 'down', down: 'up', left: 'right', right: 'left' };
    movePlayer(locationName, inverseDirection[direction], true);
  }
};

const moveNpcLocation = (npcName, locationName, newLocationName, newX, newY) => {
  const tiles = getLocationTiles(locationName);
  const npc = tiles.find(tile => tile.type === 'npc' && tile.name === npcName);

  // Move the npc to the new location
  addModifiedNpc({ name: npcName, x: newX, y: newY, location: newLocationName });

  // Replace the old NPC tile with a path tile.
  if (locationName == player.currentLocation) {
    const oldNpcTileElement = document.getElementById(`${npc.x}-${npc.y}`);
    oldNpcTileElement.innerHTML = '';
    const newTiles = getLocationTiles(locationName);
    const oldNpcTile = newTiles.find(tile => tile.x === npc.x && tile.y === npc.y);
    if (!['wall', 'path'].includes(oldNpcTile.type)) {
      const oldNpcImgElement = getTileImage(oldNpcTile);
      oldNpcTileElement.appendChild(oldNpcImgElement);
    }
  }
};

const unlockEntrance = (locationName, entranceName) => {
  const tiles = getLocationTiles(locationName);
  const entrance = tiles.find(tile => tile.type === 'entrance' && tile.location === entranceName);
  addModifiedTile(locationName, { x: entrance.x, y: entrance.y, locked: false });
};

const setupMovementListener = () => {
  document.addEventListener('keydown', async event => {
    if (locations.find(location => location.name === player.currentLocation) && !isMoving) {
      isMoving = true;
      let direction;
      switch (event.key) {
        case 'ArrowUp':
        case 'w':
        case 'W':
          direction = 'up';
          break;
        case 'ArrowDown':
        case 's':
        case 'S':
          direction = 'down';
          break;
        case 'ArrowLeft':
        case 'a':
        case 'A':
          direction = 'left';
          break;
        case 'ArrowRight':
        case 'd':
        case 'D':
          direction = 'right';
          break;
      }
      if (direction) movePlayer(player.currentLocation, direction);
      // Small delay before allowing the player to move again to prevent rapid movement.
      await util.delay(200);
      isMoving = false;
    }
  });

  // Click/tap to move functionality.
  const mainDisplay = document.getElementById('mainDisplay');
  mainDisplay.addEventListener('click', async event => {
    // Prevent attempted movement while the player is talking or fighting.
    if (!player.canExitDialogue || player.currentEnemy) return;
    if (!isMoving && locations.find(location => location.name === player.currentLocation)) {
      // Can't use simple event.target because some objects span multiple tiles.
      // Get the coordinates of where the click happened.
      const mainDisplayStyle = window.getComputedStyle(mainDisplay);
      const bodyStyle = window.getComputedStyle(document.body);
      const zoom = (bodyStyle.zoom || 1) * (mainDisplayStyle.zoom || 1);

      const clientX = event.clientX;
      const clientY = event.clientY;
      const mainDisplayX = mainDisplay.offsetLeft * zoom;
      const mainDisplayY = mainDisplay.offsetTop * zoom;
      const clickX = clientX - mainDisplayX;
      const clickY = clientY - mainDisplayY;

      // Get the dimensions of the location.
      const location = locations.find(location => location.name === player.currentLocation);
      const mainDisplayWidth = parseInt(mainDisplayStyle.width) * zoom;
      const mainDisplayHeight = parseInt(mainDisplayStyle.height) * zoom;
      const tileWidth = mainDisplayWidth / location.width;
      const tileHeight = mainDisplayHeight / location.height;

      // Find the tile that was clicked.
      const x = Math.floor(clickX / tileWidth);
      // Need to invert the y value because the grid is drawn from the top left corner.
      const y = location.height - Math.floor(clickY / tileHeight) - 1;

      await movePlayerTap(player.currentLocation, x, y);
    }
  });
};

const hideDisplay = () => {
  const mainDisplay = document.getElementById('mainDisplay');
  mainDisplay.innerHTML = '';
};

const showDisplay = () => {
  const mainDisplay = document.getElementById('mainDisplay');
  mainDisplay.style.display = 'flex';
};

export const location = {
  locations,
  getLocationTiles,
  addModifiedTile,
  displayLocation,
  moveNpc,
  moveNpcLocation,
  movePlayerTap,
  setupMovementListener,
  checkNearbyInteraction,
  unlockEntrance,
  hideDisplay,
  showDisplay,
  images,
};
