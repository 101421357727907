import { location } from '../location/index.js';
import { player } from '../player.js';
import { dialogue } from '../dialogue.js';
import { combat } from '../combat.js';

const initiateCombat = () => {
  player.currentEnemy = { ...npc };
  location.displayLocation(player.currentLocation);
};

const addCrownToInventory = () => {
  player.inventory.push({
    name: "King's Crown",
    type: 'questItem',
  });
};

const defaultOptions = [
  {
    requires: { not: 'goblinKing.askNicely' },
    display: 'Ask nicely for the crown',
    text: `"Might I humbly request the return of the king's crown, Goblin King?"`,
    next: 'goblinKing.askNicely',
  },
  {
    requires: { not: 'goblinKing.threaten' },
    display: 'Threaten the Goblin King',
    text: `"If you don't hand over the crown willingly, I will take it by force."`,
    next: 'goblinKing.threaten',
  },
  {
    requires: { not: 'goblinKing.negotiate' },
    display: 'Try to negotiate',
    text: `"Surely we can come to an agreement. What would you want in exchange for the crown?"`,
    next: 'goblinKing.negotiate',
  },
  {
    requires: { not: 'goblinKing.trick' },
    display: 'Attempt to trick the Goblin King',
    text: `"Oh, mighty Goblin King! I've actually come to warn you. The crown is cursed and will bring great misfortune to any non-human who possesses it."`,
    next: 'goblinKing.trick',
  },
  {
    display: 'Fight the Goblin King',
    text: `"Prepare yourself, Goblin King. I will take the crown by force if I must."`,
    continueFunction: initiateCombat,
  },
];

const dialogues = {
  goblinKing: {
    intro: {
      text: `"Well, well, well. What do we have here? A human daring to enter my tent?"`,
      options: [
        {
          display: 'Introduce yourself and state your purpose',
          text: `"I am Thaddeus, son of Bartholomew. I've come for the king's crown you stole."`,
          next: 'goblinKing.introduce',
        },
      ],
    },
    secondEncounter: {
      text: `"Back again, human? You must have a death wish."`,
      options: defaultOptions,
    },
    introduce: {
      text: `"Oh, have you now? And what makes you think I'll just hand it over?"`,
      options: defaultOptions,
    },
    crownFromCombat: {
      text: `"You again? I thought I told you to get out of my sight."`,
    },
    crownFromNegotiation: {
      text: `"Ah, Thaddeus. I hope you haven't forgotten our agreement."`,
    },
    crownFromTrick: {
      text: `"I see you've returned. I trust you've kept your word? Is the curse broken?"`,
      options: [
        {
          display: 'Tell him you are still working on it',
          text: `"I'm afraid the curse is proving more difficult to break than I anticipated."`,
          next: 'goblinKing.crownFromTrick.workingOnIt',
        },
        {
          display: 'Admit to failing',
          text: `"I'm sorry, Goblin King. I have failed you. The curse remains unbroken."`,
          next: 'goblinKing.crownFromTrick.failed',
        },
        {
          display: 'Laugh and reveal the deception',
          text: `"You seriously believed that nonsense about a curse? Ha! I was just trying to trick you."`,
          next: 'goblinKing.crownFromTrick.reveal',
        },
      ],
      workingOnIt: {
        text: `"I see. Well, I trust you will keep your word and continue to work on it."`,
      },
      failed: {
        text: `"I see. Well, I suppose I should have expected as much from a human."`,
      },
      reveal: {
        text: `"You dare deceive me, human? You will pay dearly for this."`,
        continueFunction: initiateCombat,
      },
    },
    askNicely: {
      text: `"You think you can just ask for the crown and I'll hand it over?"`,
      options: [
        {
          display: 'Yes...',
          text: `"Well, yes. That was the idea."`,
          next: 'goblinKing.askNicely.yes',
        },
        {
          display: 'No...',
          text: `"No, I suppose not. It was worth a try though."`,
          next: 'goblinKing.askNicely.no',
        },
      ],
      yes: {
        text: `"You humans are all the same. You think you can just ask for whatever you want and it will be given to you."`,
        options: defaultOptions,
      },
      no: {
        text: `"You're not as foolish as you look, human. But you're still not leaving here with the crown."`,
        options: defaultOptions,
      },
    },
    threaten: {
      text: `"You dare threaten me in my own domain? I am the Goblin King!"`,
      options: [
        {
          display: 'Stand your ground',
          text: `"I stand by my words, Goblin King. Hand over the crown or face the consequences."`,
          next: 'goblinKing.threaten.standGround',
        },
        {
          display: 'Apologize and try a different approach',
          text: `"I apologize for my rashness, Your Majesty. Perhaps we can discuss this civilly?"`,
          next: 'goblinKing.threaten.apologize',
        },
      ],
      standGround: {
        text: `"Then prepare to face me in combat, human. Let's see if you can back up your words with action."`,
        continueFunction: initiateCombat,
      },
      apologize: {
        text: `"So, the human can be reasonable after all. Speak, but choose your words carefully."`,
        options: defaultOptions,
      },
    },
    negotiate: {
      text: `"Interesting... I might consider a trade. But it would have to be something of great value."`,
      options: [
        {
          display: 'Offer gold',
          text: `"I could pay you for it. How much would you want?"`,
          next: 'goblinKing.negotiate.gold',
        },
        {
          display: 'Suggest a personal favor',
          text: `"Instead of material wealth, I offer you a favor. Anything within my power to grant, to be called upon at your discretion."`,
          next: 'goblinKing.negotiate.favor',
        },
        {
          display: 'Nevermind',
          text: `"Maybe we won't be able to come to an agreement after all."`,
          next: 'goblinKing.negotiate.retract',
        },
      ],
      gold: {
        text: `"Ha! You think I care about your gold? You are going to have to do better than that."`,
        options: [
          {
            display: 'Suggest a personal favor',
            text: `"Instead of material wealth, I offer you a favor. Anything within my power to grant, to be called upon at your discretion."`,
            next: 'goblinKing.negotiate.favor',
          },
          {
            display: 'Nevermind',
            text: `"Maybe we won't be able to come to an agreement after all."`,
            next: 'goblinKing.negotiate.retract',
          },
        ],
      },
      favor: {
        text: `"A blank check, eh? That's a dangerous offer, human. Are you sure you want to make it?"`,
        options: [
          {
            display: 'Confirm the offer',
            text: `"I swear on my honor, I will grant you any favor you ask of me."`,
            next: 'goblinKing.negotiate.favor.confirm',
          },
          {
            display: 'Retract the offer and suggest something else',
            text: `"On second thought, perhaps we can come to a different arrangement."`,
            next: 'goblinKing.negotiate.retract',
          },
        ],
        confirm: {
          text: `"Very well. I will hold you to your word, human. You can have the crown."`,
          options: [
            {
              display: 'Accept the crown',
              text: `"Thank you, Goblin King. I will not forget your generosity."`,
              next: 'goblinKing.negotiate.favor.confirm.accept',
            },
          ],
          accept: {
            text: `"You are free to go, Thaddeus. But remember, I will call upon you when the time comes."`,
            function: addCrownToInventory,
          },
        },
      },
      retract: {
        text: `"You are testing my patience, human. I suggest you choose your next words carefully."`,
        options: defaultOptions,
      },
    },
    trick: {
      text: `"A curse, you say? Hmm... You could be lying to trick me."`,
      options: [
        {
          display: 'Offer to "break" the curse',
          text: `"Fear not, for I have the power to break this curse. But I'll need the crown to do it."`,
          next: 'goblinKing.trick.break',
        },
        {
          display: 'Admit to the deception and try a new approach',
          text: `"You're right. I was trying to deceive you. But now I see that honesty might be the better path."`,
          next: 'goblinKing.trick.admit',
        },
      ],
      break: {
        text: `"You can break the curse? And I suppose you'll just return the crown once you've done so?"`,
        options: [
          {
            display: 'Promise to return it',
            text: `"I swear on my honor, I will return the crown to you once the curse is broken."`,
            next: 'goblinKing.trick.break.promise',
          },
          {
            display: 'Admit to the deception and try a new approach',
            text: `"You're right. I was trying to deceive you. But now I see that honesty might be the better path."`,
            next: 'goblinKing.trick.admit',
          },
        ],
        promise: {
          text: `"Very well. You may take the crown and break the curse. But remember, I will be watching."`,
          function: addCrownToInventory,
        },
      },
      admit: {
        text: `"You dare deceive me, human? You will pay dearly for this."`,
        continueFunction: initiateCombat,
      },
    },
    encounter: {
      text: `"I had a human for breakfast this morning. You'll make a fine lunch."`,
      next: 'goblinForest.combat',
    },
    defeat: {
      text: `"Impossible! How could a mere human defeat the mighty Goblin King?"`,
      options: [{ display: 'Return to map', function: () => combat.returnToMap(), next: 'goblinKing.defeat.defeated' }],
      defeated: {
        text: `"You may have won this battle, but the war is far from over. Here, take your crown and leave my sight."`,
        function: addCrownToInventory,
      },
    },
  },
};

const npc = {
  type: 'npc',
  location: 'goblinCamp',
  x: 2,
  y: 5,
  name: 'Goblin King',
  tileImage: 'goblinKing',
  dialogueKey: 'goblinKing',
  dialogueFunction: () => {
    if (!dialogue.checkDialogue('goblinKing.intro')) return 'goblinKing.intro';
    if (dialogue.checkDialogue('goblinKing.defeat.defeated')) return 'goblinKing.crownFromCombat';
    if (dialogue.checkDialogue('goblinKing.negotiate.favor.confirm.accept')) return 'goblinKing.crownFromNegotiation';
    if (dialogue.checkDialogue('goblinKing.trick.break.promise')) return 'goblinKing.crownFromTrick';
    return 'goblinKing.secondEncounter';
  },
  encounterDialogueFunction: () => 'goblinKing.encounter',
  defeatDialogueFunction: () => 'goblinKing.defeat',
  level: 15,
  hp: 85,
  maxHp: 85,
  attack: 50,
  magicAttack: 50,
  defense: 50,
  magicDefense: 30,
  gold: 100,
  boss: true,
};

export const goblinKing = { npc, dialogues };
