import { dialogue } from '../dialogue.js';
import { player } from '../player.js';
import { generateShopDialogue } from '../util/generateShopDialogue.js';

const shopItems = [
  {
    name: 'magicPotion',
    displayName: 'small magic potion',
    price: 50,
    text: `"The small magic potion will restore up to 9 magic points."`,
    buyFunction: () => {
      player.inventory.push({
        name: 'Small Magic Potion',
        type: 'combatItem',
        uses: 1,
        useDialogue: 'goblinForest.smallMagicPotion',
      });
    },
  },
  {
    name: 'healthPotion',
    displayName: 'small health potion',
    price: 50,
    text: `"The small health potion will restore up to 50 health points."`,
    buyFunction: () => {
      player.inventory.push({
        name: 'Small Health Potion',
        type: 'combatItem',
        uses: 1,
        useDialogue: 'goblinForest.smallHealthPotion',
      });
    },
  },
];

const dialogues = {
  witchHazel: {
    talk: {
      textFunction: () => {
        if (!dialogue.checkDialogue('witchHazel.talk')) return `"Welcome to my hut, traveler. I am Hazel. How can I help you?"`;
        return `"Is there anything I can help you with?"`;
      },
      options: [
        {
          requires: { not: 'witchHazel.talk.about' },
          display: 'Ask about Hazel',
          text: `"Can you tell me more about yourself, Hazel?"`,
          next: 'witchHazel.talk.about',
        },
        {
          display: 'Ask for a potion',
          text: `"Do you have any potions for sale?"`,
          next: 'witchHazel.talk.buy',
        },
        {
          display: 'Nevermind',
          function: () => {
            dialogue.clearScreen();
          },
        },
      ],
      about: {
        text: `"I have been brewing potions in the great tree of Mirewood for many years. For a small fee, I can brew you a potion to help you on your journey."`,
      },
      ...generateShopDialogue(shopItems, 'witchHazel'),
    },
  },
};

const npc = {
  type: 'npc',
  location: 'witchHut',
  x: 2,
  y: 4,
  name: 'Hazel',
  tileImage: 'witch',
  dialogueKey: 'witchHazel',
  dialogueFunction: () => 'witchHazel.talk',
};

export const witchHazel = { npc, dialogues };
