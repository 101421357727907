import { util } from '../../../util.js';
import { dialogue } from '../../../dialogue.js';
import { combat } from '../../../combat.js';
import { location } from '../../index.js';

const returnOption = [{ display: 'Return to map', function: () => combat.returnToMap() }];

const shopCellarDialogues = {
  shopCellar: {
    combat: {
      text: 'What will you do?',
      manualOptions: true,
      function: () => {
        dialogue.createOptionButtons(combat.getAttackOptions());
      },
    },
    firstRat: {
      text: 'A rat appears! I think it might be smiling? Not exactly vicious... I am not sure why Cedric is so scared of these things.',
      next: 'shopCellar.combat',
      function: () => {
        util.revealHealthAndXp();
      },
    },
    rat: {
      textFunction: () => {
        const textOptions = [
          'A rat appears! And it looks very cute! Well, it is still in your way.',
          'A rat jumps out from behind a barrel! It looks like it is ready to fight.',
          'A rat is blocking your path! Well, I guess you could walk over it, but it might bite you.',
        ];
        return textOptions[Math.floor(Math.random() * textOptions.length)];
      },
      next: 'shopCellar.combat',
    },
    firstDefeatRat: {
      text: "You defeated the rat. I hope he didn't have a family...",
      options: returnOption,
    },
    defeatRat: {
      textFunction: () => {
        const textOptions = [
          'The rat falls to the ground. Poor guy... At least he dropped some gold.',
          'You defeated the rat. It had some gold in its mouth.',
          'You defeated the rat. Hopefully it learned its lesson.',
        ];
        return textOptions[Math.floor(Math.random() * textOptions.length)];
      },
      options: returnOption,
    },
    treasure1: {
      text: 'You found 25 gold.',
      function: () => {
        util.updateGold(25);
        location.addModifiedTile('shopCellarEntrance', {
          type: 'path',
          x: 0,
          y: 5,
          tileImage: null,
          dialogueFunction: null,
          displayFunction: null,
          noMovement: null,
        });
        location.displayLocation('shopCellarEntrance');
      },
    },
    treasure2: {
      text: 'You found 25 gold.',
      function: () => {
        util.updateGold(25);
        location.addModifiedTile('shopCellarEntrance', {
          type: 'path',
          x: 6,
          y: 5,
          tileImage: null,
          dialogueFunction: null,
          displayFunction: null,
          noMovement: null,
        });
        location.displayLocation('shopCellarEntrance');
      },
    },
    wine: {
      text: 'You found a crate of vintage wine. Cedric will be pleased.',
      function: () => {
        location.addModifiedTile('shopCellarWineRoom', {
          type: 'path',
          x: 1,
          y: 2,
          tileImage: null,
          dialogueFunction: null,
          displayFunction: null,
          noMovement: null,
        });
        location.displayLocation('shopCellarWineRoom');
      },
    },
  },
};

export { shopCellarDialogues };
