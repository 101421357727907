import { player } from './player.js';
import { location } from './location/index.js';
import { dialogue } from './dialogue.js';
import { util } from './util.js';

let currentCombat = undefined;

const getAttackOptions = () => {
  return [
    {
      display: 'Physical Attack',
      ...baseAttackOption('physical'),
    },
    ...(player.mp >= 3 && !player.hideMagic
      ? [
          {
            display: 'Magic Attack',
            ...baseAttackOption('magic'),
          },
        ]
      : []),
    ...(player.inventory.filter(item => item.type === 'combatItem').length > 0
      ? [
          {
            display: 'Use Item',
            next: 'goblinForest.items',
          },
        ]
      : []),
  ];
};

const baseAttackOption = attackType => {
  return {
    function: async () => {
      dialogue.createOptionButtons();
      attack(attackType);

      await dialogue.writeText(currentCombat.player.attackMessage);
      currentCombat.enemy.updateHealthBarFunction();
      await util.delay(1000);

      if (currentCombat.enemy.lowHealthMessage) {
        await dialogue.writeText(currentCombat.enemy.lowHealthMessage);
        await util.delay(1000);
      }

      if (currentCombat.enemy.remainingHp > 0) {
        await dialogue.writeText(currentCombat.enemy.attackMessage);
        currentCombat.player.updateHealthBarFunction();
        await util.delay(1000);

        if (currentCombat.player.lowHealthMessage) {
          await dialogue.writeText(currentCombat.player.lowHealthMessage);
          await util.delay(1000);
        }
      }

      if (currentCombat.enemy.remainingHp === 0) {
        await dialogue.displayDialogue({ dialogueName: currentCombat.enemy.defeatDialogue, enemyDefeated: player.currentEnemy });
      } else if (currentCombat.player.remainingHp === 0) {
        // The player will wake up in the tower with full health.
        location.hideDisplay();
        dialogue.displayDialogue({ dialogueName: 'elarianTower.faint' });
      } else {
        await dialogue.writeText('What will you do?');
        dialogue.createOptionButtons(getAttackOptions());
      }
      currentCombat = undefined;
    },
  };
};

const returnToMap = () => {
  const locationName = player.currentLocation;
  player.previousEnemy = player.currentEnemy;
  delete player.currentEnemy;
  calculateTilesToEnemy();
  location.displayLocation(locationName);
  dialogue.clearScreen();
};

const checkForCombat = () => {
  const thisLocation = location.locations.find(location => location.name === player.currentLocation);
  if (!thisLocation.combat?.combatKey) return;

  const thisPlayerCombat = player.combat?.[thisLocation.combat.combatKey];
  if (!thisPlayerCombat) calculateTilesToEnemy();
  player.combat[thisLocation.combat.combatKey].tilesToEnemy--;

  if (player.combat[thisLocation.combat.combatKey].tilesToEnemy <= 0) {
    let enemies = thisLocation.combat.enemies;
    if (player.previousEnemy) {
      const previousEnemyIndex = thisLocation.combat.enemies.findIndex(enemy => enemy.name === player.previousEnemy?.name);
      const remainingEnemies = thisLocation.combat.enemies.filter((enemy, index) => index !== previousEnemyIndex);
      // Probability of encountering the previous enemy again is cut in half.
      enemies = [...remainingEnemies, ...enemies];
    }
    const randomEnemy = enemies[Math.floor(Math.random() * enemies.length)];
    player.currentEnemy = { ...randomEnemy };
    return true;
  }
};

const calculateTilesToEnemy = () => {
  const thisLocation = location.locations.find(location => location.name === player.currentLocation);
  if (!thisLocation.combat?.combatKey) return;
  // I had initially set the range to be 3-7, but it seemed too frequent. 4-7 seems to be a good balance.
  let minTilesToEnemy = 4;
  let maxTilesToEnemy = 7;

  // For larger maps, increase the range of tiles to the enemy to allow for more exploration without interruption.
  if (thisLocation.combat.encounterRate === 'low') {
    minTilesToEnemy = 6;
    maxTilesToEnemy = 9;
  }

  // Get a random number of tiles to the enemy within the range.
  const tilesToEnemy = Math.floor(Math.random() * (maxTilesToEnemy - minTilesToEnemy + 1) + minTilesToEnemy);

  if (!player.combat[thisLocation.combat.combatKey]) player.combat[thisLocation.combat.combatKey] = {};
  player.combat[thisLocation.combat.combatKey].tilesToEnemy = tilesToEnemy;
};

const attack = attackType => {
  const thisEnemy = player.currentEnemy;

  const playerAttack = calculateAttack(player, thisEnemy, attackType);
  const enemyAttack = calculateAttack(thisEnemy, player, 'physical');
  let playerRemainingHp = player.hp - enemyAttack.damage;
  if (playerRemainingHp < 0) playerRemainingHp = 0;
  let enemyRemainingHp = thisEnemy.hp - playerAttack.damage;
  if (enemyRemainingHp < 0) enemyRemainingHp = 0;

  if (thisEnemy.name === 'Rat Boss' && player.hideMagic && enemyRemainingHp === 0) {
    // The player should not be able to defeat the rat boss without using magic.
    enemyRemainingHp = thisEnemy.hp;
    playerAttack.isMiss = true;
  }

  if (attackType === 'physical' && !playerAttack.isMiss) {
    // Restore one magic point for every physical attack.
    player.mp = Math.min(player.mp + 1, player.maxMp);
  } else if (attackType === 'magic') {
    // Magic attacks cost three magic points.
    player.mp = Math.max(player.mp - 3, 0);
  }
  util.updateMagicBar();

  const playerMessageNumber = Math.floor(Math.random() * 3);
  let playerMessage = '';
  if (playerAttack.isMiss) playerMessage = attackMessages.miss[playerMessageNumber];
  else if (playerAttack.isCriticalHit) playerMessage = attackMessages.critical[playerMessageNumber];
  else playerMessage = attackMessages[attackType][playerMessageNumber];

  const enemyMessageNumber = Math.floor(Math.random() * 3);
  let enemyMessage = '';
  if (enemyAttack.isMiss) enemyMessage = attackMessages.enemyMiss[enemyMessageNumber];
  else if (enemyAttack.isCriticalHit) enemyMessage = attackMessages.enemyCritical[enemyMessageNumber];
  else enemyMessage = attackMessages.enemy[enemyMessageNumber];

  // Only show a low health message if their health is low for the first time.
  const playerLowHealth = player.hp / player.maxHp > 0.3 && playerRemainingHp / player.maxHp <= 0.3 && playerRemainingHp > 0;
  const enemyLowHealth = thisEnemy.hp / thisEnemy.maxHp > 0.3 && enemyRemainingHp / thisEnemy.maxHp <= 0.3 && enemyRemainingHp > 0;

  currentCombat = {
    player: {
      remainingHp: playerRemainingHp,
      attackMessage: playerMessage,
      lowHealthMessage: playerLowHealth ? attackMessages.lowHealth[Math.floor(Math.random() * 3)] : '',
      updateHealthBarFunction: () => {
        player.hp = playerRemainingHp;
        util.updateHealthBar();
      },
    },
    enemy: {
      remainingHp: enemyRemainingHp,
      attackMessage: enemyMessage,
      lowHealthMessage: enemyLowHealth ? attackMessages.enemyLowHealth[Math.floor(Math.random() * 3)] : '',
      updateHealthBarFunction: () => {
        thisEnemy.hp = enemyRemainingHp;
        util.updateHealthBar('enemyHealthLevel', thisEnemy.hp, thisEnemy.maxHp);
      },
      defeatDialogue: thisEnemy.defeatDialogueFunction(),
    },
  };

  // Replace the word "enemy" with the enemy's name in the enemy messages
  currentCombat.enemy.attackMessage = currentCombat.enemy.attackMessage.replace(
    thisEnemy.excludeThe ? 'the enemy' : 'enemy',
    thisEnemy.excludeThe ? thisEnemy.name : thisEnemy.name.toLowerCase(),
  );
  currentCombat.enemy.lowHealthMessage = currentCombat.enemy.lowHealthMessage.replace(
    thisEnemy.excludeThe ? 'the enemy' : 'enemy',
    thisEnemy.excludeThe ? thisEnemy.name : thisEnemy.name.toLowerCase(),
  );
};

const calculateAttack = (attackerStats, defenderStats, attackType) => {
  const { attack, magicAttack, level } = attackerStats;
  const { defense, magicDefense } = defenderStats;

  const isMagicAttack = attackType === 'magic';
  const relevantAttackStat = isMagicAttack ? magicAttack : attack;
  const relevantDefenseStat = isMagicAttack ? magicDefense : defense;

  // Critical hit calculation
  const baseCritChance = 0.1; // 10% base critical hit chance
  const isCriticalHit = Math.random() < baseCritChance;
  const critBonus = isCriticalHit ? 2 : 1;

  // Calculate base damage.
  const modifier = (2 * critBonus * level) / 5 + 2;
  let damage = modifier * (relevantAttackStat / relevantDefenseStat) + 2;

  // Calculate damage variation (85% to 100% of base damage).
  const damageVariation = 0.15; // 15% variation
  const minDamage = Math.ceil(damage * (1 - damageVariation));
  damage = Math.ceil(Math.random() * (damage - minDamage + 1)) + minDamage;

  // Magic attack bonus (75% to 100% of damage)
  if (isMagicAttack) {
    const magicBonus = Math.ceil(damage * 0.75 + Math.random() * (damage * 0.25));
    damage += magicBonus;
  }

  // Miss calculation
  const baseMissChance = isMagicAttack ? 0 : 0.1; // 0% for magic, 10% for physical
  const isMiss = Math.random() < Math.max(0, baseMissChance);

  if (isMiss) return { damage: 0, isMiss: true, isCriticalHit: false };

  return { damage: Math.max(1, Math.floor(damage)), isMiss: false, isCriticalHit };
};

const attackMessages = {
  physical: ['You swing your sword mightily!', 'A swift strike from your blade lands!', 'You lunge forward with a quick attack!'],
  magic: ['You cast a powerful fireball!', 'A burst of lightning erupts from your hands!', 'Mystic energies gather as you unleash a spell!'],
  enemy: ['The enemy strikes back!', 'A fierce blow from the enemy hits you!', 'You feel a sharp pain as the enemy lands an attack!'],
  critical: ['A devastating blow! You deal a critical hit!', 'Critical hit! Your attack deals a massive blow!', 'You hit a vital spot!'],
  miss: ['You miss your attack.', 'Your attack misses its mark!', 'You swing and miss.'],
  enemyCritical: ['The enemy lands a critical hit!', 'A critical hit from the enemy causes a massive blow!', 'The enemy strikes a vital spot!'],
  enemyMiss: ['The enemy misses its attack.', 'The enemy swings and misses.', 'The enemy attacks and misses.'],
  lowHealth: ['You are barely standing.', 'You feel weak and exhausted.', "You're hanging on by a thread."],
  enemyLowHealth: ['Your opponent is on the brink of defeat.', 'The enemy struggles to stay standing.', 'The enemy is barely holding on.'],
};

export const combat = { getAttackOptions, returnToMap, checkForCombat };
