import { dialogue } from '../../dialogue.js';
import { generateStatueDialogue } from '../../util/generateStatueDialogue.js';
import { shopCellarDialogues } from './shopCellar/dialogues.js';

const elarianDialogues = {
  ...shopCellarDialogues,
  elarianGates: {
    intro: {
      text: `You are Thaddeus, a peasant from a small village on the outskirts of the kingdom of Glenfall.
      Ever since you were a kid, you have dreamed of being able to save Glenfall from The Dark One who has been terrorizing the kingdom for centuries.
      You finally saved up enough as a butcher's apprentice to buy a ride to Elarian, Glenfall's capital city.
      You hope to be able to speak with the king and get his blessing on your quest to defeat The Dark One.`,
      next: 'elarianGates.intro2',
    },
    intro2: {
      text: `After a long journey, you finally arrive at the city gates. Use the arrow keys, wasd, or click any tile to move.
      Press "e" or click on the inventory icon in the top-left to open your inventory.`,
    },
  },
  elarianShop: {
    lockedCellar: {
      text: 'The cellar is locked.',
    },
  },
  elarianTower: {
    faint: {
      text: `Everything goes black... You slowly regain consciousness.`,
      nextFunction: () => {
        if (!dialogue.checkDialogue('wizardEldwin.firstFaint')) return 'wizardEldwin.firstFaint';
        else return 'wizardEldwin.faint';
      },
    },
  },
  elarianCourtyard: {
    investigateDragonStatue: {
      text: `The text on the statue reads: "Here lies the great dragon, protector of Glenfall. May his spirit forever watch over us."
      It looks like the statue has a small slit near the base, just big enough to fit your sword...`,
      includeClose: true,
      options: [{ display: 'Put your sword in the slit', next: 'elarianCourtyard.statueActions' }],
    },
    ...generateStatueDialogue('elarianCourtyard'),
  },
};

export { elarianDialogues };
