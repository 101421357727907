import { dialogue } from '../../dialogue.js';

const mirewoodLocations = [
  {
    name: 'mirewoodCourtyard',
    width: 7,
    height: 7,
    pathImage: 'swampFloor',
    tiles: [
      { type: 'object', x: 0, y: 0, tileImage: 'tree', noMovement: true },
      { type: 'object', x: 1, y: 0, tileImage: 'tree', noMovement: true },
      { type: 'object', x: 2, y: 0, tileImage: 'tree', noMovement: true },
      { type: 'object', x: 4, y: 0, tileImage: 'tree', noMovement: true },
      { type: 'object', x: 5, y: 0, tileImage: 'tree', noMovement: true },
      { type: 'object', x: 6, y: 0, tileImage: 'tree', noMovement: true },
      { type: 'entrance', x: 3, y: 0, location: 'goblinForestTop2', outputDirection: 'up' },
      {
        type: 'object',
        x: 3,
        y: 3,
        tileImage: 'basiliskStatue',
        noMovement: true,
        displayFunction: () => {
          if (!dialogue.checkDialogue('mirewoodCourtyard.investigateBasiliskStatue')) return 'Investigate the basilisk statue';
          else return 'Put your sword in the slit';
        },
        dialogueFunction: () => {
          if (!dialogue.checkDialogue('mirewoodCourtyard.investigateBasiliskStatue')) return 'mirewoodCourtyard.investigateBasiliskStatue';
          return 'mirewoodCourtyard.statueActions';
        },
      },
      { type: 'entrance', x: 3, y: 6, location: 'mirewood', outputDirection: 'down' },
    ],
  },
  {
    name: 'mirewood',
    width: 9,
    height: 9,
    pathImage: 'swampFloor',
    tiles: [
      { type: 'entrance', x: 4, y: 0, location: 'mirewoodCourtyard', outputDirection: 'up' },
      // { type: 'entrance', x: 6, y: 6, tileImage: 'mirewoodTavern', location: 'mirewoodTavern', outputDirection: 'down' },
      // { type: 'entrance', x: 2, y: 6, tileImage: 'mirewoodHouse', outputDirection: 'down', location: 'mirewoodHouse' },
      { type: 'entrance', x: 4, y: 4, tileImage: 'witchHut', outputDirection: 'down', location: 'witchHut' },
      { type: 'object', x: 4, y: 3, tileImage: 'giantTree' },
    ],
  },
  {
    name: 'witchHut',
    width: 5,
    height: 6,
    pathImage: 'woodFloor',
    wallImage: 'stoneBrick',
    walls: ['bottom'],
    tiles: [
      { type: 'entrance', x: 2, y: 0, tileImage: 'door', tileBackground: 'wall', location: 'mirewood', outputDirection: 'up' },
      { type: 'object', x: 2, y: 3, tileImage: 'cauldron', 'z-index': 3 },
      { type: 'object', x: 0, y: 5, tileImage: 'bookshelf', noMovement: true },
    ],
  },
];

export { mirewoodLocations };
