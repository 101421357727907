import { player } from '../player.js';
import { util } from '../util.js';
import { dialogue } from '../dialogue.js';

// Example usage:
// const shopItems = [
//   {
//     name: 'bladeSharpener',
//     displayName: 'blade sharpener',
//     price: 100,
//     text: `"The blade sharpener is a must-have for any adventurer. It will make your sword sharper and more effective in battle." (Attack +10)`,
//     oneTime: true,
//     buyFunction: () => {
//       player.attack += 10;
//     },
//   },
// ];

export const generateShopDialogue = (shopItems, shopkeeperName) => {
  const buyOptions = [
    ...shopItems.map(item => ({
      ...(item.oneTime ? { requires: { not: `${shopkeeperName}.talk.buy.buy${item.name}` } } : {}),
      display: `Ask about the ${item.displayName} (${item.price} gold)`,
      //text: `"Tell me about the ${item.displayName}."`,
      next: `${shopkeeperName}.talk.buy.${item.name}`,
    })),
    {
      display: 'Nevermind',
      function: () => {
        dialogue.clearScreen();
      },
    },
  ];

  return {
    buy: {
      text: `"Here, take a look! Is there anything you are interested in?"`,
      options: buyOptions,
      ...shopItems.reduce((acc, item) => {
        acc[item.name] = {
          text: item.text,
          options: [
            {
              display: `Buy the ${item.displayName} (${item.price} gold)`,
              nextFunction: () => {
                if (player.gold < item.price) return `${shopkeeperName}.talk.buy.cantBuy${item.name}`;
                else return `${shopkeeperName}.talk.buy.buy${item.name}`;
              },
            },
            {
              display: 'Back',
              next: `${shopkeeperName}.talk.buyQuestionOnly`,
            },
          ],
        };

        acc[`cantBuy${item.name}`] = {
          text: `"You don't have enough gold for that."`,
          next: `${shopkeeperName}.talk.buyQuestionOnly`,
        };

        acc[`buy${item.name}`] = {
          text: `"Thank you for your purchase!"`,
          function: () => {
            util.updateGold(-item.price);
            item.buyFunction();
          },
          next: `${shopkeeperName}.talk.buyQuestionOnly`,
        };

        return acc;
      }, {}),
    },
    buyQuestionOnly: {
      text: `"Is there anything you are interested in?"`,
      options: buyOptions,
    },
  };
};
