import { generateStatueDialogue } from '../../util/generateStatueDialogue.js';

const mirewoodDialogues = {
  mirewoodCourtyard: {
    investigateBasiliskStatue: {
      text:
        'The text on the statue reads: "The basilisk used to prowl the swamps of Mirewood, turning all who looked into its eyes to stone until he looked at his reflection and turned himself to stone."' +
        'This statue also has a small slit for your sword...',
      includeClose: true,
      options: [{ display: 'Put your sword in the slit', next: 'mirewoodCourtyard.statueActions' }],
    },
    ...generateStatueDialogue('mirewoodCourtyard'),
  },
};

export { mirewoodDialogues };
