import { util } from '../util.js';
import { dialogue } from '../dialogue.js';
import { location as locationObj } from '../location/index.js';

const fastTravelLocations = [
  { name: 'elarianCourtyard', display: 'Elarian Courtyard' },
  { name: 'mirewoodCourtyard', display: 'Mirewood Courtyard' },
];

const requireFastTravelObject = {
  or: [],
};

// Generate all possible combinations of two locations
for (let i = 0; i < fastTravelLocations.length; i++) {
  for (let j = i + 1; j < fastTravelLocations.length; j++) {
    requireFastTravelObject.or.push({
      and: [{ check: `${fastTravelLocations[i].name}.statueActions` }, { check: `${fastTravelLocations[j].name}.statueActions` }],
    });
  }
}

export const generateStatueDialogue = locationName => {
  return {
    statueActions: {
      textFunction: () => {
        const includesAnotherStatue = fastTravelLocations.some(location => dialogue.checkDialogue(`${location.name}.statueActions`));
        let additionalMessage = '';
        if (includesAnotherStatue && !dialogue.checkDialogue(`${locationName}.statueActions`)) {
          additionalMessage = `You are now able to fast travel to this location from any statue! `;
        } else if (!includesAnotherStatue) {
          additionalMessage = `You can use statues to save your game and fast travel if you have used more than one statue! `;
        }

        return `You put your sword in the slit. ${additionalMessage}What would you like to do?`;
      },
      includeClose: true,
      options: [
        { display: 'Save your game', next: `${locationName}.statueActions.saveGame` },
        {
          requires: requireFastTravelObject,
          display: 'Fast travel',
          next: `${locationName}.statueActions.fastTravel`,
        },
      ],
      saveGame: {
        text: `Your game has been saved!`,
        // Save the game before the dialogue is shown, since that leaves less room for the player to close the game before the save is complete.
        prefunction: () => {
          util.saveGame();
        },
      },
      fastTravel: {
        text: `Where would you like to fast travel?`,
        includeClose: true,
        options: fastTravelLocations
          .map(location => ({
            requires: { check: `${location.name}.statueActions` },
            display: location.display,
            next: `${locationName}.statueActions.fastTravel.${location.name}`,
          }))
          .filter(option => option.next !== `${locationName}.statueActions.fastTravel.${locationName}`),
        ...fastTravelLocations.reduce((acc, location) => {
          acc[location.name] = {
            text: `The statue around your sword transforms. You are now in the ${location.display}!`,
            prefunction: async () => {
              // This works as long as the statues are all located at 3, 3.
              // If the statues are located at different coordinates, then then this will need to be updated.
              await locationObj.displayLocation(location.name);
            },
          };
          return acc;
        }, {}),
      },
    },
  };
};
