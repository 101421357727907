const items = [
  {
    name: "King's Crown",
    type: 'questItem',
    image: new URL('../../images/crown.png?width=256&as=webp', import.meta.url),
  },
  {
    name: 'Ogre Spray',
    type: 'combatItem',
    uses: 3,
    useDialogue: 'goblinForest.ogreSpray',
    image: new URL('../../images/ogre-spray.png?width=256&as=webp', import.meta.url),
  },
  {
    name: 'Small Magic Potion',
    type: 'combatItem',
    uses: 1,
    useDialogue: 'goblinForest.smallMagicPotion',
    image: new URL('../../images/small-magic-potion.png?width=256&as=webp', import.meta.url),
  },
  {
    name: 'Small Health Potion',
    type: 'combatItem',
    uses: 1,
    useDialogue: 'goblinForest.smallHealthPotion',
    image: new URL('../../images/small-health-potion.png?width=256&as=webp', import.meta.url),
  },
];

export { items };
