import { dialogue } from '../../../dialogue.js';

const cellarCombat = {
  combatKey: 'shopCellar',
  enemies: [
    {
      name: 'rat',
      tileImage: 'rat',
      encounterDialogueFunction: () => {
        if (!dialogue.checkDialogue('shopCellar.firstRat')) return 'shopCellar.firstRat';
        return 'shopCellar.rat';
      },
      defeatDialogueFunction: () => {
        if (!dialogue.checkDialogue('shopCellar.firstDefeatRat')) return 'shopCellar.firstDefeatRat';
        return 'shopCellar.defeatRat';
      },
      level: 1,
      hp: 15,
      maxHp: 15,
      attack: 2,
      magicAttack: 2,
      defense: 10,
      magicDefense: 10,
      gold: 5,
    },
  ],
};

export { cellarCombat };
