import { player } from '../player.js';
import { items } from './items.js';
import './index.css';
let inventoryOpen = false;

const closeInventoryClickListener = e => {
  if (e.target.id !== 'inventoryModalContent' && e.target.id !== 'inventoryIcon') closeInventory();
};
const openInventory = () => {
  inventoryOpen = true;
  document.body.insertAdjacentHTML(
    'beforeend',
    /*html*/ `
    <div id="inventoryModalShadow"></div>
    <div id="inventoryModalContent">
    <div class="inventory-section" id="itemsSection">
      <div class="inventory-header" id="goldSection">
        <img src="${new URL(
          '../../images/gold-coin.png?width=512&as=webp',
          import.meta.url,
        )}" alt="Gold" class="gold-icon" title="gold" draggable="false">
        <div>${player.gold}</div>
      </div>
      <div class="inventory-header">Items</div>
      <div class="inventory-items">
        ${player.inventory
          .map(item => {
            const image = items.find(i => i.name === item.name).image;

            return /*html*/ `
              <div class="inventory-item">
                <img src="${image}" alt="${item.name}" class="inventory-item-image" title="${item.name}" draggable="false">
                <div>${item.name}</div>
              </div>
            `;
          })
          .join('')}
      </div>
    </div>
    <div class="inventory-section" id="equipmentSection">
      <div class="close-inventory" id="closeInventory">Close</div>
      <div class="inventory-header">
        <div>Level ${player.level}</div>
      </div>
      <img src="${new URL(
        '../../images/thaddeus.png?width=512&as=webp',
        import.meta.url,
      )}" alt="Thaddeus" class="inventory-thaddeus-image" draggable="false">   
      </div>   
    </div>
  `,
  );

  document.getElementById('inventoryModalShadow')?.addEventListener('click', closeInventoryClickListener);
  document.getElementById('closeInventory')?.addEventListener('click', closeInventory);
};

const closeInventory = () => {
  document.getElementById('inventoryModalContent')?.remove();
  document.getElementById('inventoryModalShadow')?.remove();
  inventoryOpen = false;
};

const toggleInventory = () => {
  if (inventoryOpen) closeInventory();
  else openInventory();
};

const setupInventoryListeners = () => {
  const inventoryIcon = document.getElementById('inventoryIcon');
  inventoryIcon.addEventListener('click', toggleInventory);
  document.addEventListener('keydown', e => {
    if (e.key === 'e') {
      toggleInventory();
    }
  });
};

export { setupInventoryListeners };
