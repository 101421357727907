import { location } from '../../location/index.js';
import { util } from '../../util.js';

const dialogues = {
  courtyardGuard: {
    lockedForest: {
      text: `"The forest is too dangerous for civilians. I can't let you pass until you have the proper authorization."`,
      prefunction: async () => {
        location.moveNpc('elarianCourtyard', 'Courtyard Guard 1', 'up');
        await util.delay(250);
        location.moveNpc('elarianCourtyard', 'Courtyard Guard 1', 'up');
        await util.delay(250);
        location.moveNpc('elarianCourtyard', 'Courtyard Guard 1', 'up');
        await util.delay(250);
        location.moveNpc('elarianCourtyard', 'Courtyard Guard 1', 'up');
        await util.delay(250);
        location.moveNpc('elarianCourtyard', 'Courtyard Guard 1', 'right');
        await util.delay(250);
      },
      function: async () => {
        location.moveNpc('elarianCourtyard', 'Courtyard Guard 1', 'down');
        await util.delay(250);
        location.moveNpc('elarianCourtyard', 'Courtyard Guard 1', 'left');
        await util.delay(250);
        location.moveNpc('elarianCourtyard', 'Courtyard Guard 1', 'down');
        await util.delay(250);
        location.moveNpc('elarianCourtyard', 'Courtyard Guard 1', 'down');
        await util.delay(250);
        location.moveNpc('elarianCourtyard', 'Courtyard Guard 1', 'down');
      },
    },
    greeting1: {
      text: `"Hey there! Welcome to the Elarian Courtyard. It's a beautiful day, isn't it?"`,
    },
    greeting2: {
      text: `"Hello! Don't forget to take a look at the statue in the center of the courtyard. I hear it's magical."`,
    },
  },
};

const getDefaultDialogue = () => {
  return;
};

const npcs = [
  {
    type: 'npc',
    location: 'elarianCourtyard',
    x: 2,
    y: 1,
    name: 'Courtyard Guard 1',
    displayName: 'Courtyard Guard',
    tileImage: 'guard',
    dialogueKey: 'courtyardGuard',
    dialogueFunction: () => {
      const defaultDialogue = getDefaultDialogue();
      if (defaultDialogue) return defaultDialogue;
      else return 'courtyardGuard.greeting1';
    },
  },
  {
    type: 'npc',
    location: 'elarianCourtyard',
    x: 4,
    y: 1,
    name: 'Courtyard Guard 2',
    displayName: 'Courtyard Guard',
    tileImage: 'guard',
    dialogueKey: 'courtyardGuard',
    // Shares dialogues with Courtyard Guard 1, since they have the same display name.
    dialogueFunction: () => {
      const defaultDialogue = getDefaultDialogue();
      if (defaultDialogue) return defaultDialogue;
      else return 'courtyardGuard.greeting2';
    },
  },
];

export const courtyardGuards = { npcs, dialogues };
