import { dialogue } from '../dialogue.js';
import { player } from '../player.js';
import { util } from '../util.js';
import { location } from '../location/index.js';

const restoreHealth = () => {
  player.hp = player.maxHp;
  player.mp = player.maxMp;
  util.updateHealthBar();
  util.updateMagicBar();
};

const faint = async () => {
  player.x = 3;
  player.y = 4;
  delete player.currentEnemy;
  location.showDisplay();
  await location.displayLocation('elarianTower');
  restoreHealth();
};

const dialogues = {
  wizardEldwin: {
    talk: {
      textFunction: () => {
        if (!dialogue.checkDialogue('wizardEldwin.talk')) return `"Ah, a visitor! Welcome to my humble tower! I am Eldwin. What can I do for you?"`;
        return `"Is there anything I can help you with?"`;
      },
      options: [
        {
          requires: { not: 'wizardEldwin.talk.about' },
          display: 'Ask about Eldwin',
          text: `"Could you tell me more about yourself, Eldwin?"`,
          next: 'wizardEldwin.talk.about',
        },
        {
          requires: { not: 'wizardEldwin.talk.learnMagic' },
          display: 'Ask if he can teach you magic',
          text: `"Would you be willing to teach me some magic?"`,
          nextFunction: () => {
            if (!dialogue.checkDialogue('wizardEldwin.firstFaint')) return 'wizardEldwin.talk.cantLearnMagic';
            else return 'wizardEldwin.talk.learnMagic';
          },
        },
        {
          requires: { and: [{ check: 'kingRehoboam.audienceConclusion' }, { not: 'wizardEldwin.talk.explainPlan' }] },
          display: 'Ask about the plan',
          text: `"The king mentioned a plan to defeat the Dark One. Can you tell me more about it?"`,
          next: 'wizardEldwin.talk.explainPlan',
        },
        {
          display: `Ask to restore your health`,
          text: `"Can you restore my health?"`,
          nextFunction: () => {
            if (player.hp === player.maxHp && player.mp === player.maxMp) return 'wizardEldwin.talk.fullHealth';
            else return 'wizardEldwin.talk.restoreHealth';
          },
        },
        {
          display: 'Nevermind',
          function: () => {
            dialogue.clearScreen();
          },
        },
      ],
      about: {
        text: `"I have been studying magic in my tower for decades. I have mastered the art of casting spells and have just started to experiment with potions."`,
      },
      cantLearnMagic: {
        text: `"I could teach you some basic spells, but first you will need combat experience. Come back when you have fought in a few battles."`,
      },
      learnMagic: {
        text: `"Let me walk you through how to use magic. You only have a limited amount of magic that you can use before you need to recover. You can only recover magic when you use a physical attack. By using magic attacks, you should have the strength you need to defeat the rat boss. Good luck!"`,
        prefunction: () => {
          util.revealMagic();
          util.updateMagicBar();
        },
      },
      fullHealth: {
        text: `"You are already at full health, my friend."`,
      },
      restoreHealth: {
        text: `"There you go! You are now at full health."`,
        prefunction: restoreHealth,
      },
      explainPlan: {
        text: `"Ah, yes, of course. The Dark One's power comes from an ancient artifact known as the Shadowstone. If we can destroy it, we can defeat the Dark One. However, the Shadowstone is protected by his minions, deep within his fortress."`,
        next: 'wizardEldwin.talk.continueExplanation',
      },
      continueExplanation: {
        text: `"I am working on a potion that will make you look like one of the Dark One's minions. If you can infiltrate his fortress and get close enough to the Shadowstone, you can destroy it from the inside."`,
        next: 'wizardEldwin.talk.finalExplanation',
      },
      finalExplanation: {
        text: `"But, there's a catch. I still need three more ingredients to finish the potion and I will need your help to retrieve them."`,
        options: [
          {
            display: 'Offer to help Eldwin',
            text: `"I will help you gather the ingredients. What exactly do you need?"`,
            next: 'wizardEldwin.talk.finalExplanation.offerHelp',
          },
        ],
        offerHelp: {
          text: `"The developer of this game, Phoenix Flame, has not yet told me what the ingredients are. But, I am sure they will be interesting to find. Thank you for playing beta version 0.3 of Glenfall!"`,
        },
      },
    },
    firstFaint: {
      text: `"You passed out in the cellar and I brought you here to recover. You are now at full health, my friend. Learning some magic could help you defeat the rat boss. I can teach you some basic spells if you are interested."`,
      prefunction: async () => {
        await faint();

        // Set the talk dialogue to seen so that Eldwin doesn't call you a visitor.
        dialogue.setDialogueSeen('wizardEldwin.talk');
      },
    },
    faint: {
      text: `"I have restored you to full health. You should be more careful out there, my friend."`,
      prefunction: faint,
    },
  },
};

const npc = {
  type: 'npc',
  location: 'elarianTower',
  x: 2,
  y: 4,
  name: 'Eldwin',
  tileImage: 'wizardEldwin',
  dialogueKey: 'wizardEldwin',
  dialogueFunction: () => 'wizardEldwin.talk',
};

export const wizardEldwin = { npc, dialogues };
